import React, { useEffect } from 'react';
import { Formik } from 'formik';

import CreatableSelectInput from 'lib/CreateableSelect';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import TeamCollection from '../../../domain/Team';
import { useTranslation, withTranslation } from 'react-i18next';
import { getMatchConfigurationInput } from './MatchConfigurationInput';
import CheckboxScheduling from './MatchConfigurationInput/CheckboxScheduling';
import moment, { isMoment } from 'moment';
import { Session } from 'domain/Session';
import ReactTooltip from 'react-tooltip';
import { LockedContent } from 'lib/LicenseLimitEnforcing';
import Loading from 'lib/Loading';
import { Tooltip } from '../../../lib/Tooltip';
import { gotoRoute } from '../../route';

const initialValues = {
  homeTeam: '',
  awayTeam: '',
  name: '',
  scheduledAt: roundToNearest5Min(moment()),
  matchConfig: {},
  recordingType: 'video',
};

function roundToNearest5Min(start) {
  return moment(start).add(
    Math.ceil(start.minute() / 5) * 5 - start.minute(),
    'minutes'
  );
}

const NewMatchModalContent = withTranslation('module.match.new')((props) => {
  const { onAdd, onCancel, sportType, t } = props;

  const {
    Component: MatchConfigurationInput,
    getInitialValues: getMatchConfigurationInitialValues,
    getValues: getMatchConfigurationValues,
    validator: matchConfigurationValidator,
  } = getMatchConfigurationInput(sportType, { type: 'match' });

  let currentSession = Session.current();

  const handleSubmit = (values, formikBag) => {
    const matchConfig = getMatchConfigurationValues(values.matchConfig);

    onAdd({
      homeTeam: values.homeTeam.value,
      awayTeam: values.awayTeam.value,
      scheduledAt: values.scheduledAt,
      automaticCameraConfig: values.automaticCameraConfig,
      matchConfig,
      recordingType: values.recordingType,
    });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.homeTeam) {
      errors.homeTeam = true;
    }

    if (!values.awayTeam) {
      errors.awayTeam = true;
    }

    if (!values.scheduledAt) {
      errors.scheduledAt = true;
    }

    if (
      values?.automaticCameraConfig?.record &&
      values?.automaticCameraConfig?.fieldId === 'unset'
    ) {
      errors.fieldId = true;
    }

    const matchConfigErrors = matchConfigurationValidator(values.matchConfig);
    for (const k of Object.keys(matchConfigErrors)) {
      errors[`matchConfig_${k}`] = matchConfigErrors[k];
    }
    return errors;
  };

  initialValues.matchConfig = getMatchConfigurationInitialValues();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({
        handleSubmit,
        values,
        submitCount,
        errors,
        setFieldValue,
        onReset,
        isSubmitting,
      }) => {
        const teamOptions = TeamCollection.uniqueArrayEditable().map(
          (team) => ({
            value: team.id,
            label: team.label,
          })
        );

        const ownTeamId = currentSession?.currentResource()?.teamId;

        teamOptions.sort((a, b) => {
          if (a.value === ownTeamId) return -1; // Own team goes first
          if (b.value === ownTeamId) return 1; // Own team goes first
          const labelA = a.label;
          const labelB = b.label;
          return (labelA > labelB) - (labelA < labelB);
        });

        const validClassName = (key) => {
          if (!submitCount) {
            return '';
          }

          return errors[key] ? 'is-invalid' : 'is-valid';
        };

        const handleAutomaticCameraConfig = (value) => {
          if (value.record) {
            setFieldValue('recordingType', 'autocam');
          } else {
            setFieldValue('recordingType', 'video');
          }
          setFieldValue('automaticCameraConfig', value);
        };

        const datePickerProps = {};
        if (values.recordingType === 'future') {
          datePickerProps.minDate = moment();
        }
        if (values.recordingType === 'video') {
          datePickerProps.maxDate = moment();
        }
        if (values.recordingType === 'autocam') {
          datePickerProps.minDate = moment();
        }

        const checkNewOption = (value) => {
          let existingTeam = teamOptions.find((t) => t.label === value);
          if (existingTeam) {
            console.log('team already exists, selecting team');
            return existingTeam;
          } else {
            return { label: value, value: value };
          }
        };
        return (
          <form
            onSubmit={!isSubmitting ? handleSubmit : (e) => e.preventDefault()}
          >
            <div className="modal-header">
              <h5 className="modal-title">{t('title')}</h5>
              <p>{t('description')}</p>
            </div>
            <div className="modal-body">
              <div className="row">
                <RecordingTypeSelection
                  setFieldValue={(key, value) => {
                    if (key === 'recordingType' && value !== 'autocam') {
                      setFieldValue('automaticCameraConfig', {
                        ...values.automaticCameraConfig,
                        record: false,
                      });
                    }
                    setFieldValue(key, value);
                  }}
                  values={values}
                  selectIcon={values.recordingType}
                  sportingEventType="match"
                />
                <div className="col-12">
                  <div className={`form-group ${validClassName('homeTeam')}`}>
                    <label htmlFor="new-match-home-team">
                      {t('homeTeamLabel')} <GoToTeamManagementTooltip />
                    </label>
                    <CreatableSelectInput
                      id="new-match-home-team"
                      name="homeTeam"
                      className="custom-form-control"
                      value={values.homeTeam}
                      isClearable
                      onChange={(option) => {
                        if (option.label == option.value) {
                          option = checkNewOption(option.value);
                        }
                        setFieldValue('homeTeam', option);
                      }}
                      options={teamOptions}
                      placeholder={t('homeTeamPlaceholder')}
                      styles={{
                        option: (providedStyles, props) => {
                          if (props.data.value === ownTeamId) {
                            return {
                              ...providedStyles,
                              fontWeight: 'bold',
                              opacity: 0.9,
                            };
                          }
                          return {
                            ...providedStyles,
                            fontWeight: props.data.__isNew__ && 'bold',
                          };
                        },
                      }}
                      formatCreateLabel={(inputValue) =>
                        `+ ${t('common:create', { label: inputValue })}`
                      }
                      createOptionPosition="first"
                    />
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className={`form-group ${validClassName('awayTeam')}`}>
                    <label htmlFor="new-match-away-team">
                      {t('awayTeamLabel')}
                      <GoToTeamManagementTooltip />
                    </label>
                    <CreatableSelectInput
                      id="new-match-away-team"
                      name="awayTeam"
                      className="custom-form-control"
                      value={values.awayTeam}
                      isClearable
                      onChange={(option) => {
                        if (option.label == option.value) {
                          option = checkNewOption(option.value);
                        }
                        setFieldValue('awayTeam', option);
                      }}
                      options={teamOptions}
                      placeholder={t('awayTeamPlaceholder')}
                      formatCreateLabel={(inputValue) =>
                        t('common:create', { label: inputValue })
                      }
                      styles={{
                        option: (providedStyles, props) => {
                          if (props.data.value === ownTeamId) {
                            return {
                              ...providedStyles,
                              fontWeight: 'bold',
                              opacity: 0.9,
                            };
                          }
                          return {
                            ...providedStyles,
                            fontWeight: props.data.__isNew__ && 'bold',
                          };
                        },
                      }}
                    />
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                </div>
                {!['live', 'tagging'].includes(values.recordingType) && (
                  <div className="col-12">
                    <div
                      className={`form-group ${validClassName('scheduledAt')}`}
                    >
                      <label htmlFor="new-match-scheduled-at">
                        {t('scheduledAt')}
                      </label>
                      <div className="d-flex">
                        <a
                          className={'datepicker-button'}
                          onClick={() => {
                            setFieldValue(
                              'scheduledAt',
                              roundToNearest5Min(moment())
                            );
                          }}
                        >
                          {t('now')}
                        </a>
                        <DatePicker
                          id="new-match-scheduled-at"
                          name="scheduledAt"
                          className="form-control-datepicker"
                          selected={moment(values.scheduledAt)}
                          onChange={(value) => {
                            value &&
                              isMoment(value) &&
                              setFieldValue(
                                'scheduledAt',
                                value.startOf('minute')
                              );
                          }}
                          showTimeSelect
                          autoComplete="off"
                          dateFormat="LLL"
                          timeFormat="HH:mm"
                          timeCaption="Tijd"
                          isClearable={false}
                          timeIntervals={5}
                          placeholderText={t('scheduledAtPlaceholder')}
                          popperPlacement="top"
                          {...datePickerProps}
                        />
                      </div>

                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                )}

                {MatchConfigurationInput && (
                  <MatchConfigurationInput
                    submitCount={submitCount}
                    errors={errors.matchConfig}
                    values={values.matchConfig}
                    setFieldValue={(field, value) =>
                      setFieldValue(`matchConfig.${field}`, value)
                    }
                    validClassName={(field) =>
                      validClassName(`matchConfig_${field}`)
                    }
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <CheckboxScheduling
                  callback={handleAutomaticCameraConfig}
                  values={values}
                  errors={errors}
                  type={props.type}
                  validClassName={(k) => validClassName(k)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="form-actions">
                <button
                  type={isSubmitting ?? 'submit'}
                  className="btn btn-primary"
                >
                  {t('addMatch')}{' '}
                  {isSubmitting && (
                    <Loading type={'spinner'} color={'white'} size={16} />
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={onCancel}
                >
                  {t('common:cancel')}
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
});

const NewTrainingModalContent = withTranslation('module.match.new')((props) => {
  const { onAdd, onCancel, sportType, t } = props;

  const handleSubmit = (values, formikBag) => {
    onAdd({
      scheduledAt: values.scheduledAt,
      name: values.name,
      recordingType: values.recordingType,
      automaticCameraConfig: values.automaticCameraConfig,
    });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = true;
    }

    if (!values.scheduledAt) {
      errors.scheduledAt = true;
    }
    if (
      values?.automaticCameraConfig?.record &&
      values?.automaticCameraConfig?.fieldId === 'unset'
    ) {
      errors.fieldId = true;
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({
        handleSubmit,
        values,
        submitCount,
        errors,
        setFieldValue,
        onReset,
        isSubmitting,
      }) => {
        const validClassName = (key) => {
          if (!submitCount) {
            return '';
          }

          return errors[key] ? 'is-invalid' : 'is-valid';
        };

        const handleAutomaticCameraConfig = (value) => {
          if (value.record) {
            setFieldValue('recordingType', 'autocam');
          } else {
            setFieldValue('recordingType', 'video');
          }
          setFieldValue('automaticCameraConfig', value);
        };
        const datePickerProps = {};
        if (values.recordingType === 'future') {
          datePickerProps.minDate = moment();
        }
        if (values.recordingType === 'video') {
          datePickerProps.maxDate = moment();
        }
        if (values.recordingType === 'autocam') {
          datePickerProps.minDate = moment();
        }

        return (
          <form
            onSubmit={!isSubmitting ? handleSubmit : (e) => e.preventDefault()}
          >
            <div className="modal-header">
              <h5 className="modal-title">{t('addTraining')}</h5>
              <p>{t('addTrainingDescription')}</p>
            </div>
            <div className="modal-body">
              <div className="row">
                <RecordingTypeSelection
                  setFieldValue={(key, value) => {
                    if (key === 'recordingType' && value !== 'autocam') {
                      setFieldValue('automaticCameraConfig', {
                        ...values.automaticCameraConfig,
                        record: false,
                      });
                    }
                    setFieldValue(key, value);
                  }}
                  values={values}
                  selectIcon={values.recordingType}
                  sportingEventType="training"
                />
                <div className="col-12">
                  <div className={`form-group ${validClassName('name')}`}>
                    <label htmlFor="new-training-name">{t('name')}</label>
                    <input
                      type="text"
                      id="new-match-away-team"
                      name="name"
                      className="form-control"
                      value={values.name}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                      placeholder={t('name')}
                    />
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                </div>
                {!['live', 'tagging'].includes(values.recordingType) && (
                  <div className="col-12">
                    <div
                      className={`form-group ${validClassName('scheduledAt')}`}
                    >
                      <label htmlFor="new-match-scheduled-at">
                        {t('scheduledAt')}
                      </label>
                      <div className="d-flex">
                        <a
                          className={'datepicker-button'}
                          onClick={() => {
                            setFieldValue(
                              'scheduledAt',
                              roundToNearest5Min(moment())
                            );
                          }}
                        >
                          {t('now')}
                        </a>
                        <DatePicker
                          id="new-match-scheduled-at"
                          name="scheduledAt"
                          className="form-control-datepicker"
                          selected={moment(values.scheduledAt)}
                          onChange={(value) => {
                            value &&
                              isMoment(value) &&
                              setFieldValue(
                                'scheduledAt',
                                value.startOf('minute')
                              );
                          }}
                          showTimeSelect
                          autoComplete="off"
                          dateFormat="LLL"
                          timeFormat="HH:mm"
                          timeCaption="Tijd"
                          isClearable={false}
                          timeIntervals={5}
                          placeholderText={t('scheduledAtPlaceholder')}
                          popperPlacement="top"
                          {...datePickerProps}
                        />
                      </div>
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {Session.current().isFeatureAvailable('automaticCamera') && (
                // turn on automatic camera for training.
                <div className="row">
                  <div className="col-12">
                    <CheckboxScheduling
                      callback={handleAutomaticCameraConfig}
                      values={values}
                      errors={errors}
                      type={props.type}
                      validClassName={(k) => validClassName(k)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <div className="form-actions">
                <button type="submit" className="btn btn-primary">
                  {t('addTraining')}
                  {isSubmitting && (
                    <Loading type={'dots'} color={'white'} size={16} />
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={onCancel}
                >
                  {t('common:cancel')}
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
});

const RecordingTypeSelection = ({
  setFieldValue,
  selectIcon,
  sportingEventType,
  values,
}) => {
  const currentSession = Session.current();
  const isAvailableLiveRecording =
    currentSession.isFeatureAvailable('liveRecord');
  const isAvailableLiveTagging =
    currentSession.isFeatureAvailable('liveObserve');
  const { t } = useTranslation('module.match.new');

  useEffect(() => {
    if (isAvailableLiveRecording && window.isSkillReflect) {
      setFieldValue('recordingType', 'live'); // Set live recording as default value in skillreflect
    }
  }, []);

  const icons = [
    {
      order: 2,
      name: 'video',
      label: t('recordingVideo'),
      icon: 'upload',
      action: () => {
        setFieldValue('scheduledAt', roundToNearest5Min(moment()));
        setFieldValue('recordingType', 'video');
      },
      tooltip: t('recordingVideoTooltip'),
    },
    window.isSkillreflect
      ? {
          order: 0,
          name: 'live',
          label: t('recordingLive'),
          icon: 'liverecord',
          locked: [
            {
              check: !isAvailableLiveRecording,
              reason: 'recordingLiveLockedTooltip',
            },
          ],
          action: () => {
            if (isAvailableLiveRecording) {
              setFieldValue('scheduledAt', roundToNearest5Min(moment()));
              setFieldValue('recordingType', 'live');
            }
          },
          tooltip: t('recordingLiveTooltip'),
        }
      : {
          order: 0,
          name: 'autocam',
          label: 'Automatische opname inplannen',
          icon: 'autocam',
          locked: [
            {
              check: !currentSession.isFeatureAvailable('automaticCamera'),
              reason: 'autoCamLockedTooltip',
            },
          ],
          action: () => {
            if (currentSession.isFeatureAvailable('automaticCamera')) {
              setFieldValue(
                'scheduledAt',
                roundToNearest5Min(moment().add(15, 'minutes'))
              );
              setFieldValue('recordingType', 'autocam');
              setFieldValue('automaticCameraConfig', {
                ...values.automaticCameraConfig,
                record: true,
              });
            }
          },
          tooltip: 'Plan je wedstrijd opname met automatische camera in',
        },
    {
      order: 1,
      name: 'tagging',
      label: t('recordingTagging'),
      icon: 'observe',
      locked: [
        {
          check: !isAvailableLiveTagging,
          reason: 'recordingTaggingLockedTooltip',
          lockType: 'hidden',
        },
      ],
      action: isAvailableLiveTagging
        ? () => {
            setFieldValue('scheduledAt', roundToNearest5Min(moment()));
            setFieldValue('recordingType', 'tagging');
          }
        : () => {},
      tooltip: t('recordingTaggingTooltip'),
    },
    {
      order: 3,
      name: 'future',
      label: t('recordingFuture', {
        typeLabel: t(`module.match:type.${sportingEventType}`),
      }),
      icon: 'calendar-small',
      action: () => {
        setFieldValue(
          'scheduledAt',
          roundToNearest5Min(moment().add(1, 'days'))
        );
        setFieldValue('recordingType', 'future');
      },
      tooltip: t('recordingFutureTooltip', {
        typeLabel: t(`module.match:type.${sportingEventType}`),
      }),
    },
  ];

  return (
    <div className="col-12">
      <label>{t('recordingType')}</label>
      <div className="form-group icon-button-container">
        {icons
          .sort((a, b) => a.order - b.order)
          .map((icon) => (
            <LockedContent place={'left'} size={'large'} reasons={icon.locked}>
              {(isLocked) => {
                return (
                  <div
                    data-tip={''}
                    data-for={`tooltip--${icon.name}`}
                    className={`icon-select ${
                      selectIcon === icon.name && 'active'
                    } ${isLocked && 'disabled'}`}
                    onClick={() => icon.action()}
                  >
                    <i className={`i-${icon.icon}`} />
                    {icon.label}
                    {icon.tooltip && !isLocked && (
                      <ReactTooltip
                        id={`tooltip--${icon.name}`}
                        type="dark"
                        effect="solid"
                        place={'top'}
                        backgroundColor="#495057"
                        className={'tooltip--styled'}
                        delayShow={500}
                      >
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                          {icon.tooltip}
                        </div>
                      </ReactTooltip>
                    )}
                  </div>
                );
              }}
            </LockedContent>
          ))}
      </div>
    </div>
  );
};

const GoToTeamManagementTooltip = () => {
  // todo: translate and add link
  const { t } = useTranslation('module.match.new');
  return (
    <Tooltip
      delayHide={500}
      delayShow={500}
      delayUpdate={500}
      clickable={true}
      tooltip={() => (
        <div>
          {t('teamsManagementTooltip')}
          <a
            href={'#'}
            onClick={(e) => {
              e.preventDefault();
              gotoRoute('user-management.local-teams-management');
            }}
          >
            {' '}
            {t('teamsManagement')}
          </a>
        </div>
      )}
    >
      <i className={'i-knowledge-base i-sm ml-1'} />
    </Tooltip>
  );
};

export {
  NewMatchModalContent as NewMatchInput,
  NewTrainingModalContent as NewTrainingInput,
};
