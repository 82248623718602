import { action, observable, runInAction, when } from 'mobx';

import * as Sentry from '@sentry/react';
import ReconnectingWebSocket from 'reconnecting-websocket';

import UserCollection from '../domain/User';
import TeamCollection from '../domain/Team';
import { appCollection } from '../domain/App';
import ResourceGroupCollection from '../domain/ResourceGroup';
import { sharingGroupCollection } from '../domain/SharingGroup';

import KorfballDashboardLayout from '../modules/reporting/containers/korfbal';
import TennisDashboardLayout from '../modules/reporting/containers/Tennis';

import HockeyDashboardLayout from '../modules/reporting/containers/Hockey';
import HandballDashboardLayout from '../modules/reporting/containers/handball';

import SoccerDashboardLayout from '../modules/reporting/containers/Soccer';
import SoccerPassMapDashboardLayout from '../modules/reporting/containers/SoccerPassMap';

import {
  VideoFragmentCollector,
  TeamVideoFragmentGenerator,
} from 'lib/VideoFragment';
import {
  AllGoalsVideoFragmentGenerator,
  PersonVideoFragmentGenerator,
  KorfballVideoFragmentGenerator,
  HandballVideoFragmentGenerator,
  TennisVideoFragmentGenerator,
} from 'lib/VideoFragment/Generator/index';

import Authentication from '../utils/Authentication';
import { AppManager } from 'domain/App';
import axios from 'axios';
import moment from 'moment';

class SportRules {
  get substitutionCount() {
    throw 'implement me';
  }

  get periodDuration() {
    throw 'implement me';
  }

  get periodCount() {
    throw 'implement me';
  }

  get defaultRecordingDuration() {
    return 75;
  }
  get recordingBeforeMargin() {
    return 5;
  }
}

class KorfballRules extends SportRules {
  get substitutionCount() {
    return 8;
  }

  get periodDuration() {
    return 30 * 60;
  }

  get periodCount() {
    return 2;
  }
}

class HockeyRules extends SportRules {
  get substitutionCount() {
    return 0;
  }

  get periodDuration() {
    return 30 * 60;
  }

  get periodCount() {
    return 4;
  }

  get defaultRecordingDuration() {
    return 95;
  }

  get recordingBeforeMargin() {
    return 5;
  }
}

class TennisRules extends SportRules {
  get substitutionCount() {
    return 0;
  }

  get periodDuration() {
    return 4500; // duration is nog fixed in tennis
  }

  get periodCount() {
    return 2;
  }
}

class HandballRules extends SportRules {
  get substitutionCount() {
    return 0;
  }

  get periodDuration() {
    return 30 * 60;
  }

  get periodCount() {
    return 2;
  }
}

class SoccerRules extends SportRules {
  get substitutionCount() {
    return 3;
  }

  get periodDuration() {
    return 45 * 60;
  }

  get periodCount() {
    return 2;
  }

  get defaultRecordingDuration() {
    // H1 + HT + H2 + Some margin
    return 45 + 15 + 45 + 15;
  }
  get recordingBeforeMargin() {
    return 5;
  }
}

class PlaceholderRules extends SportRules {
  get substitutionCount() {
    return 0;
  }

  get periodDuration() {
    return 0;
  }

  get periodCount() {
    return 1;
  }
}

class Privileges {
  constructor(privileges) {
    this.privileges = privileges;
  }

  hasPrivilege(privilege) {
    return this.privileges.indexOf(privilege) !== -1;
  }
}

class AuthenticatedWebSocket {
  constructor(url, token, onMessage) {
    this.url = url;
    this.token = token;
    this.socket = null;
    this.onMessage = onMessage;

    this.connect();
  }

  connect() {
    const socket = new ReconnectingWebSocket(this.url);

    socket.addEventListener('open', (event) => {
      socket.send(
        JSON.stringify({
          command: 'authenticate',
          token: this.token,
        })
      );
    });

    socket.addEventListener('message', (event) => {
      this.onMessage(JSON.parse(event.data));
    });
  }
}
class Session {
  static _instance = null;
  static current() {
    if (this._instance === null) {
      this._instance = new Session();
    }
    return this._instance;
  }

  constructor() {
    this.user = UserCollection.build({ userId: 'me' });
    when(() => Authentication.isAuthenticated, this.reloadSettings.bind(this));
    when(() => Authentication.isAuthenticated, this.openServices.bind(this));
    this._currentResource = null;
    this._currentResourceGroup = null;
    this._appManager = new AppManager(this);

    this.onReadyPromise = new Promise((success, error) => {
      this.onSuccess = success;
      this.onError = error;
    });
    this._handlers = {};
    this.onReadyPromise
      .catch(() => {})
      .then(() => {
        this.isCameraAvailableAtLocalClub();
        this.onReadyPromise.done = true;
      });

    this._cameraAtLocalClubToken = observable(false);
  }

  on(eventName, handler) {
    if (!this._handlers[eventName]) {
      this._handlers[eventName] = [];
    }
    this._handlers[eventName].push(handler);
  }

  // returns string like "team"
  currentResourceGroupType() {
    if (!this.isMember()) {
      return null;
    }
    const currentTargetResourceId =
      this.user.get('currentMembership').membership.targetResourceId;
    if (currentTargetResourceId) {
      const [resourceType, resourceId] = currentTargetResourceId.split(':');
      return resourceType;
    }
    return null;
  }

  personType() {
    const currentMembership = this.currentMembership();
    if (currentMembership) {
      if (currentMembership.resourceGroupTags) {
        return currentMembership.resourceGroupTags.personType;
      }
    }
  }

  onUserInfoAvailable(user) {
    Sentry.setUser({
      email: user.email,
      id: user.realUserId,
      name: `${user.firstName} ${user.lastName}`,
    });
    Sentry.setContext('currentMembership', user.currentMembership.membership);
  }

  onUpdateResourceGroup(user) {
    window.localStorage.setItem(
      'com.teamtv.lastResourceGroupId',
      JSON.stringify(this.resourceGroupId())
    );
  }

  resourceGroupId() {
    if (this.isMember()) {
      return this.currentMembership().resourceGroupId;
    }
  }

  setResourceGroupId(resourceGroupId, location = '/') {
    window.localStorage.setItem(
      'com.teamtv.lastResourceGroupId',
      JSON.stringify(resourceGroupId)
    );
    window.location = location;
    // window.location.reload();
  }

  possibleResourceGroupId() {
    let lastResourceGroupId = window.localStorage.getItem(
      'com.teamtv.lastResourceGroupId'
    );
    if (
      lastResourceGroupId !== null &&
      lastResourceGroupId !== undefined &&
      lastResourceGroupId !== 'undefined'
    ) {
      try {
        lastResourceGroupId = JSON.parse(lastResourceGroupId);
      } catch (e) {
        window.localStorage.removeItem('com.teamtv.lastResourceGroupId');
        lastResourceGroupId = undefined;
      }
    } else {
      lastResourceGroupId = undefined;
    }
    return lastResourceGroupId;
  }

  async openServices() {
    const { notifications } = await this.user.getRequest('services');
    if (notifications) {
      const websocket = new AuthenticatedWebSocket(
        notifications.urls.websocket,
        notifications.token
      );
      websocket.onMessage = (msg) => {
        for (const handler of this._handlers['notifications.message'] || []) {
          handler(msg);
        }
      };

      const authRequestInterceptor = (config) => {
        config.headers.Authorization = `Bearer ${notifications.token}`;
        return config;
      };

      const api = axios.create({
        baseURL: notifications.urls.api,
      });
      api.interceptors.request.use(authRequestInterceptor, null, {
        synchronous: true,
      });
      this.services = {
        notifications: {
          api,
        },
      };
    }
  }
  async getNotificationPreferences() {
    if (!this?.services?.notifications) {
      await this.openServices();
    }
    return this.services.notifications.api.get('userPreferences');
  }

  async setNotificationPreferences(data) {
    return this.services.notifications.api.patch('userPreferences', data);
  }

  async reloadSettings(refreshUser = true) {
    try {
      if (refreshUser) {
        await this.user.fetch();
      }

      this.onUpdateResourceGroup(this.user.toJS());

      await this._setCurrentResource();

      this.onUserInfoAvailable(this.user.toJS());

      this.onSuccess();
    } catch (e) {
      console.log(e);
      this.onError(e);
    }
  }

  isCameraAvailableAtLocalClub = action(async () => {
    let appAvailable = appCollection
      .toJS()
      .find((app) => app.attributes.appId === 'eyedle-automatic-camera-system');
    if (!this._cameraAtLocalClubToken.get() && appAvailable) {
      let app = await appCollection.getOrFetch(
        'eyedle-automatic-camera-system'
      );
      let AppCredentials = await app.launch();
      // Update observable state within an action to respect strict mode
      runInAction(() => {
        if (!this._cameraAtLocalClubToken.get()) {
          // don't update token when it is already set.
          this._cameraAtLocalClubToken.set(AppCredentials.token);
        }
      });
    }
    return false;
  });

  cameraAtLocalClubToken() {
    return this._cameraAtLocalClubToken.get();
  }

  async canConnectToClubServer() {
    let app = await appCollection.getOrFetch('eyedle-automatic-camera-system');
    let AppCredentials = await app.launch();
    let availability;

    if (AppCredentials) {
      try {
        availability = await fetch(
          `${AppCredentials.appOrigin}/ping?token=${AppCredentials.token}`,
          { signal: AbortSignal.timeout(1000) }
        );
      } catch (e) {
        console.log(e);
      }
    }
    if (availability) {
      return true;
    }

    return false;
  }

  isReady() {
    return this.onReadyPromise;
  }

  async _setCurrentResource() {
    if (!this.user.get('currentMembership').membership) {
      return;
    }

    await appCollection.fetchIfEmpty();

    this._currentResourceGroup = ResourceGroupCollection.build({
      resourceGroupId: this.currentMembership().resourceGroupId,
    });

    window.currentResourceGroup = this._currentResourceGroup;

    const currentTargetResourceId =
      this.user.get('currentMembership').membership.targetResourceId;
    if (currentTargetResourceId) {
      try {
        const [resourceType, resourceId] = currentTargetResourceId.split(':');
        switch (resourceType) {
          case 'team':
            this._currentResource = await TeamCollection.getOrFetch(resourceId);
            break;
          case 'SharingGroup':
            this._currentResource = sharingGroupCollection.build({
              sharingGroupId: resourceId,
            });
            await this._currentResource.fetch();
            break;
        }
      } catch (e) {
        this._currentResource = null;
      }
    }
  }

  currentResourceGroup() {
    return this._currentResourceGroup;
  }

  isMember() {
    return this.user.has('currentMembership') && !!this.currentMembership();
  }

  currentMembership() {
    if (!this.user.has('currentMembership')) {
      return null;
    }
    return this.user.get('currentMembership').membership;
  }

  enabledUserFeatures() {
    return Object.entries(this.user.get('features'))
      .filter(([feature, config]) => config.enabled)
      .map(([feature, config]) => feature);
  }

  currentParentResourceGroupId() {
    if (!this.user.has('currentMembership')) {
      return null;
    }
    return this.user.get('currentMembership').parentResourceGroupId;
  }

  currentParentResourceGroup() {
    return this.user
      .get('memberships')
      ?.find(
        (m) =>
          m.resourceGroupId ===
          this.user.get('currentMembership')?.parentResourceGroupId
      );
  }

  currentPrivileges() {
    const privileges = this.user.get('currentMembership').privileges;
    if (!privileges) {
      return [];
    }
    const { user, license } = privileges;
    return new Privileges(
      user.filter((privilege) => license.indexOf(privilege) !== -1)
    );
  }

  licensePrivileges() {
    const privileges = this.user.get('currentMembership').privileges;
    if (!privileges) {
      return false;
    }
    const { user, license } = privileges;
    return new Privileges(license);
  }

  currentMembershipLogo() {
    return this.currentMembership()
      ? this.user.get('currentMembership').appearance.logoUrl
      : undefined;
  }

  currentResourceGroup() {
    return this._currentResourceGroup;
  }

  memberships(filter_ = {}) {
    let memberships_ = this.user.get('memberships');
    if (filter_.resourceTypeName) {
      const resourceTypeName = filter_.resourceTypeName;

      memberships_ = memberships_.filter((membership) => {
        return (
          membership.targetResourceId.substring(
            0,
            resourceTypeName.length + 1
          ) ===
          resourceTypeName + ':'
        );
      });
    }
    return memberships_;
  }

  isMemberOf(resourceGroupId) {
    return !!this.memberships().find(
      (membership) => membership.resourceGroupId === resourceGroupId
    );
  }

  currentObjectId() {
    return this.currentMembership().targetResourceId.split(':')[1];
  }

  // string like: "PSV 1"
  targetResourceName() {
    return this.currentMembership().targetResourceName;
  }

  currentResource() {
    return this._currentResource;
  }

  sportType() {
    if (this.personType() === 'trainee') {
      return null;
    }
    if (this._currentResource) {
      let sportType =
        this._currentResource.has('sportType') &&
        this._currentResource.get('sportType');
      if (!sportType && this._currentResource.has('attributes')) {
        sportType = this._currentResource.get('attributes').sportType;
      }
      return sportType;
    }
    const currentMembership = this.currentMembership();
    if (currentMembership) {
      if (currentMembership.resourceGroupTags) {
        return currentMembership.resourceGroupTags.sportType;
      }
    }
  }

  currentTenantId() {
    const currentMembership = this.currentMembership();
    if (currentMembership) {
      return currentMembership.tenantId;
    } else {
      return null;
    }
  }

  currentTenantIsNew() {
    const tenantCreated = this.user.has('currentMembership')
      ? this.user.get('currentMembership')?.tenant?.created
      : null;
    if (tenantCreated) {
      return moment().startOf('day').diff(moment(tenantCreated), 'days') < 60;
    } else {
      return false; //If we don't have info make false negative.
    }
  }

  currentTenantIsNewerThan(days) {
    const tenantCreated = this.user.has('currentMembership')
      ? this.user.get('currentMembership')?.tenant?.created
      : null;
    if (tenantCreated) {
      return moment().startOf('day').diff(moment(tenantCreated), 'days') < days;
    } else {
      return false; //If we don't have info make false negative.
    }
  }

  currentPersonId() {
    const currentMembership = this.currentMembership();
    if (currentMembership) {
      return currentMembership.memberAttributes.personId;
    } else {
      return null;
    }
  }

  getDefaultObservationInputName({ live = false } = {}) {
    const tenantId = this.currentTenantId();

    switch (this.sportType()) {
      case 'korfball':
        if (live === true) {
          return 'korfball.live.default';
        } else {
          return 'korfball.video.default';
        }
      case 'other':
        return 'other.video.default';
      case 'handball':
        if (live === true) {
          return 'handball.live.default';
        } else {
          return 'handball.video.default';
        }
      case 'tennis':
        return 'tennis.video.default';
      case 'soccer':
        if (live === true) {
          if (
            tenantId === 'nl_soccer_heerenveen' ||
            tenantId === 'nl_soccer_wvf'
          ) {
            return 'soccer.live.heerenveen';
          } else if (
            tenantId === 'nl_soccer_sportbeat' ||
            tenantId === 'nl_soccer_koenstest'
          ) {
            return 'broadcast.live.default';
          } else {
            return 'soccer.live.passmap';
          }
        } else {
          return 'soccer.video.default';
        }
      case 'hockey':
        if (live === true) {
          return 'hockey.live.default';
        } else {
          return 'hockey.video.default';
        }

      default:
        return null;
        throw "Don't know observationInputName for " + this.sportType();
    }
  }

  getDefaultReportId() {
    const tenantId = this.currentTenantId();

    switch (this.sportType()) {
      case 'korfball':
        return 'korfball';
      case 'hockey':
        return 'hockey';
      case 'handball':
        return 'korfball';
      case 'tennis':
        return 'korfball';
      case 'soccer':
        if (tenantId === 'nl_soccer_goaheadeagles') {
          return 'soccer-passmap';
        } else {
          return 'soccer';
        }
      default:
        throw "Don't know reportId for " + this.sportType();
    }
  }

  getDefaultDownloadReportId() {
    if (window.isSkillReflect) {
      return 'skillreflect';
    }
    return null;
  }

  getVideoFragmentCollector() {
    const videoFragmentCollector = new VideoFragmentCollector();
    const teamVideoFragmentGenerator = new TeamVideoFragmentGenerator();
    videoFragmentCollector.addGenerator(teamVideoFragmentGenerator);

    const personId = this.currentPersonId();

    if (!!personId) {
      videoFragmentCollector.addGenerator(
        new PersonVideoFragmentGenerator(personId)
      );
    }
    switch (this.sportType()) {
      case 'korfball':
        teamVideoFragmentGenerator.setSubGenerators([
          new KorfballVideoFragmentGenerator(),
        ]);
        break;

      case 'handball':
        teamVideoFragmentGenerator.setSubGenerators([
          new HandballVideoFragmentGenerator(),
        ]);
        break;

      case 'tennis':
        teamVideoFragmentGenerator.setSubGenerators([
          new TennisVideoFragmentGenerator(),
        ]);
        break;
    }
    videoFragmentCollector.addGenerator(new AllGoalsVideoFragmentGenerator());
    return videoFragmentCollector;
  }

  getDefaultDashboardLayout() {
    const tenantId = this.currentTenantId();

    switch (this.sportType()) {
      case 'korfball':
        return KorfballDashboardLayout;
      case 'hockey':
        return HockeyDashboardLayout;
      case 'tennis':
        return TennisDashboardLayout;
      case 'soccer':
        if (tenantId === 'nl_soccer_goaheadeagles') {
          return SoccerPassMapDashboardLayout;
        } else {
          return SoccerDashboardLayout;
        }
      case 'handball':
        return HandballDashboardLayout;

      default:
        throw "Don't know dashboardLayout for " + this.sportType();
    }
  }

  getClubAdminMembership() {
    /*
    Return membership for current user with club admin privileges

    1. Check if current group is club admin group
    2. Check parent resource group id
    3. Fallback to check club resource group with same tenant id -> this breaks when multiple clubs within
       single tenant

     */
    const tenantId = this.currentTenantId();

    // 1. Is current group admin group?
    const currentResourceGroupType = this.currentResourceGroupType();
    if (
      currentResourceGroupType === 'club' ||
      currentResourceGroupType === 'education'
    ) {
      return this.currentMembership();
    }

    const parentResourceGroupId = this.currentParentResourceGroupId();
    if (!!parentResourceGroupId) {
      const adminMembership = this.memberships({ resourceTypeName: 'club' })
        .concat(this.memberships({ resourceTypeName: 'education' }))
        .find(
          (membership) => membership.resourceGroupId === parentResourceGroupId
        );
      if (!!adminMembership) {
        return adminMembership;
      }
    }

    const clubAdminMembership = this.memberships({
      resourceTypeName: 'club',
    }).find((membership) => {
      return (
        membership.tenantId === tenantId &&
        (membership.roleNames.indexOf('Admin') !== -1 ||
          membership.roleNames.indexOf('admin') !== -1)
      );
    });
    if (!!clubAdminMembership) {
      return clubAdminMembership;
    }
    return this.memberships({
      resourceTypeName: 'education',
    }).find((membership) => {
      return (
        membership.tenantId === tenantId &&
        (membership.roleNames.indexOf('Admin') !== -1 ||
          membership.roleNames.indexOf('admin') !== -1)
      );
    });
  }

  getClubAdminResourceGroupId() {
    const membership = this.getClubAdminMembership();
    if (typeof membership !== 'undefined') {
      return membership.resourceGroupId;
    }
    return undefined;
  }

  getClubAdminResourceGroupName() {
    const membership = this.getClubAdminMembership();
    if (typeof membership !== 'undefined') {
      return membership.targetResourceName;
    }
    return undefined;
  }

  hasClubAdminPrivileges() {
    // return true if there is a role where current user has Club Admin p
    return typeof this.getClubAdminMembership() !== 'undefined';
  }

  isTrainer() {
    if (!this.isMember()) {
      return false;
    }

    const currentMembership = this.currentMembership();
    const roleNames = currentMembership ? currentMembership.roleNames : [];

    const targetResourceType = currentMembership
      ? currentMembership.targetResourceId.split(':')[0]
      : null;

    return (
      roleNames.indexOf('Trainer') !== -1 ||
      roleNames.indexOf('Coach') !== -1 ||
      roleNames.indexOf('trainer') !== -1 ||
      roleNames.indexOf('coach') !== -1 ||
      (roleNames.indexOf('admin') !== -1 &&
        targetResourceType === 'SharingGroup')
    );
  }

  currentRoleName() {
    if (!this.isMember()) {
      return 'no-membership';
    }
    const currentMembership = this.currentMembership();
    const roleNames = currentMembership ? currentMembership.roleNames : [];
    if (roleNames.length === 0) {
      return 'unknown';
    } else {
      return roleNames[0];
    }
  }

  // TODO: @benvds create observation input manager

  isFeatureAvailable(featureName) {
    const tenantId = this.currentTenantId();
    const sportType = this.sportType();
    const isTrainer = this.isTrainer();

    const isAdmin = this.hasClubAdminPrivileges();

    const privileges = this.currentPrivileges();

    switch (featureName) {
      case 'exportObservations':
        return (
          (sportType === 'korfball' ||
            (sportType === 'soccer' && tenantId === 'nl_soccer_heerenveen')) &&
          isTrainer &&
          !window.isSkillReflect
        );
      case 'importObservations':
        return (
          privileges.hasPrivilege('sporting-event:observe') &&
          (sportType === 'soccer' ||
            sportType === 'hockey' ||
            ['nl_korfball_koenstest1337'].indexOf(tenantId) !== -1) &&
          isTrainer &&
          !window.isSkillReflect
        );
      case 'videoObserve':
        return privileges.hasPrivilege('sporting-event:observe');

      case 'manageCustomTags':
        return privileges.hasPrivilege('CustomTag:create');
      case 'deleteVideo':
        return privileges.hasPrivilege('video:destroy');
      case 'automaticCamera':
        return privileges.hasPrivilege(
          'sporting-event:set-automatic-camera-config'
        );

      case 'createSportingEvent':
        return privileges.hasPrivilege('sporting-event:create');
      case 'deleteSportingEvent':
        return privileges.hasPrivilege('sporting-event:destroy');
      case 'viewSportingEventDetails':
        return (
          isTrainer &&
          (tenantId === 'nl_korfball_kwcu17' ||
            tenantId === 'nl_handball_aalsmeer' ||
            tenantId === 'nl_korfball_petertest' ||
            tenantId === 'nl_korfball_valto' ||
            this.user.email === 'support@teamtv.nl')
        );

      case 'liveObserve':
        return isTrainer || this.personType() === 'trainee';
      case 'liveRecord':
        return (
          this.licensePrivileges().hasPrivilege('video:live-record') &&
          this.isFeatureAvailable('liveObserve')
        );
      case 'livestreamOverlay':
        // Only allow the overlay for Camera tenants
        return (
          isTrainer &&
          privileges.hasPrivilege('sporting-event:set-automatic-camera-config')
        );
      case 'exportReport':
        return privileges.hasPrivilege('sporting-event:export');
      case 'teamNames':
      case 'observeBroadcast':
        return !window.isSkillReflect;
      case 'observeDirectDetails':
        return window.isSkillReflect;

      case 'publishHighlightRender':
        return tenantId === 'be_hockey_braxgata';

      case 'uploadVideo':
        return (
          (sportType === 'hockey' ||
            sportType === 'handball' ||
            sportType === 'soccer' ||
            sportType === 'tennis' ||
            sportType === 'korfball') &&
          isTrainer
        );
      case 'liveReport':
        return (
          sportType === 'korfball' ||
          sportType === 'hockey' ||
          sportType === 'handball' ||
          (sportType === 'soccer' &&
            (tenantId === 'nl_soccer_goaheadeagles' ||
              tenantId === 'nl_soccer_koenstest'))
        );
      case 'viewReport':
        return (
          (sportType === 'korfball' ||
            sportType === 'hockey' ||
            // sportType === 'soccer' || See:https://github.com/teamtv/platform-frontend/issues/962
            sportType === 'handball' ||
            sportType === 'tennis') &&
          !window.isSkillReflect
        );
      case 'viewObserveGraph':
        return (
          tenantId === 'nl_korfball_koenstest1337' ||
          tenantId === 'nl_korfball_wageningen' ||
          tenantId === 'nl_korfball_oec' ||
          tenantId === 'nl_korfball_associatievoorcoaching'
        );
      case 'manageLineUp':
        return sportType === 'korfball' && isTrainer && !window.isSkillReflect;
      case 'manageUsers':
        return privileges.hasPrivilege('resourceGroup:manage-members');
      case 'manageShares':
        return (
          privileges.hasPrivilege('resourceGroup:manage-shares') &&
          !window.isSkillReflect
        );
      case 'observeDevelopment':
        // Tagging for LearningLines (markers for SkillReflect)
        return (
          (privileges.hasPrivilege('LearningLine:observe') ||
            (privileges.hasPrivilege('LearningLine:view') &&
              this.personType() === 'trainee')) &&
          window.isSkillReflect
        );
      case 'observeLearningLine':
        // Legacy stuff for tagging good/bad examples, that show up in the development overview.
        // TODO: consider removing entirely
        return (
          privileges.hasPrivilege('LearningLine:observe') ||
          ([
            'nl_korfball_koenstest1337',
            'nl_korfball_associatievoorcoaching',
            'nl_korfball_ikf',
            'nl_korfball_oostarnhem',
          ].indexOf(tenantId) !== -1 &&
            this.personType() !== 'trainee')
        );
      case 'scoreBar':
        return !window.isSkillReflect && this.personType() !== 'trainee';
      case 'manageTeams':
        return isAdmin;
      case 'hasStore':
        return isAdmin || this.currentResourceGroupType() === 'user';
      case 'play':
        return true;
      case 'exchange':
        return sportType !== 'tennis' && !window.isSkillReflect;
      case 'editSportingEvent':
        return privileges.hasPrivilege('sporting-event:edit');
      case 'editShareSportingEvent':
        return (
          privileges.hasPrivilege('sporting-event:edit') &&
          window.isSkillReflect
        );
      case 'addTrainee':
        return window.isSkillReflect || tenantId === 'nl_korfball_ikf';
      case 'playlistChat':
        return tenantId !== 'nl_korfball_knkvscheidsrechters';
      case 'viewPlaylist':
        return privileges.hasPrivilege('playlist:view');

      case 'viewDevelopment':
        return (
          privileges.hasPrivilege('LearningLine:view') &&
          privileges.hasPrivilege('DevelopmentPlan:view')
        );
      case 'viewPOP':
        return (
          privileges.hasPrivilege('LearningLine:view') &&
          privileges.hasPrivilege('DevelopmentPlan:view') &&
          window.isSkillReflect
        );
      case 'evaluateDevelopment':
        return isTrainer;
      case 'instant-download':
        return this.licensePrivileges().hasPrivilege('video:instant-download');

      case 'editLearningGroups':
        return privileges.hasPrivilege('LearningGroup:edit');
      case 'shareVideo':
        return this.enabledUserFeatures().includes('shareVideo');
      case 'submitVersion':
        return privileges.hasPrivilege('Assignment:submit');
      case 'acceptVersion':
      case 'rejectVersion':
        return privileges.hasPrivilege('Assignment:assess');

      case 'manageSchedule':
        return (
          isAdmin && this.licensePrivileges().hasPrivilege('schedule:edit')
        );
      case 'buySubscription':
        return this.licensePrivileges().hasPrivilege('subscription:buy');
      case 'automaticCameraUseTaggingData':
        return (
          this.isFeatureAvailable('automaticCamera') &&
          this.sportType() === 'hockey'
        );
      case 'automaticCameraUseLivestream':
        return [
          'at_hockey_arminen',
          'nl_korfball_pkc',
          'nl_hockey_eyedle',
          'nl_korfball_bouncespace',
          'be_hockey_braxgata',
          'nl_soccer_tyme',
        ].includes(tenantId);
      case 'broadcast':
        return (
          isTrainer &&
          ([
            //Southgate HC W1's
            'bdcc29fc-73ac-11ec-8c47-dba33f9be1e1',
            //KoensTest1337 1 / E2E
            '53283e64-e9cb-11e9-a386-c32ac4cd8189',
            'a269242e-7f88-11ed-9358-6b68bca75937',
            //  Southgate HC M1's
            'ff0b5a74-79eb-11ec-be96-43bc7f620f3a',
          ].includes(this.currentResourceGroup().id) ||
            ['be_hockey_braxgata'].includes(tenantId))
        );
      default:
        throw `Unknown feature ${featureName}`;
    }
  }

  getSportRules() {
    switch (this.sportType()) {
      case 'hockey':
        return new HockeyRules();
      case 'korfball':
        return new KorfballRules();
      case 'soccer':
        return new SoccerRules();
      case 'handball':
        return new HandballRules();
      case 'tennis':
        return new TennisRules();
      default:
        return new PlaceholderRules();
      // throw "Don't know the rules";
    }
  }

  getAppManager() {
    return this._appManager;
  }
}

export { Session };
